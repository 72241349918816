* {
  margin: 0;
  padding: 0;
  list-style: none;
}

html,
body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  color: $text-color;
  background-color: $background-color;
}

// Scrollbar bug fix on mobile
@media screen and (min-width: 960px) {
  html {
    margin-left: calc(100vw - 100%);
    margin-right: 0;
    margin-left: 0;
  }
}

#root {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  font-family: $main-font;
}

main {
  display: flex;
  flex-grow: 1;
  padding: $spacing-gutter;
  position: relative;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

h1.page-heading {
  color: $color-grey-primary;
  font-family: $main-font;
  font-size: 25px;
  font-weight: 600;
}
