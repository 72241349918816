@use "assets/styles/global" as *;

.details-information {
  form {
    display: flex;
    flex-direction: column;
    gap: 16px;

    .form-group {
      max-width: 400px;
      
      label {
        font-weight: bold;
      }
    }
  }
}
