@use "assets/styles/global" as *;

$row-gap: 1rem;
$column-gap: 10px;

.formsection {
  font-family: $main-font;
  display: flex;
  row-gap: $row-gap;
  column-gap: $column-gap;
  flex-wrap: wrap;

  padding: 1rem 0 2rem;
  margin: 1rem 0;

  border-bottom: 1px solid $border-color;

  &.no-border {
    padding: 0;
    border-bottom: none;
  }

  .full,
  .half,
  .third {
    width: 100%;
  }

  @include md {
    .half {
      width: calc(50% - ($column-gap/2));
    }

    .third {
      width: calc(33% - ($column-gap / 3));
    }
  }
}

.formsection-title {
  width: 100%;
  font-size: 16px;
  font-weight: 500;
}
