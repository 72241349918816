@use "assets/styles/global" as *;

.go-back-link {
  align-items: center;
  color: $color-grey-quaternary;
  font-style: normal;
  display: flex;
  gap: 8px;
  text-decoration: none;
}
