@use "assets/styles/global" as *;

.table-row-wrapper {
  text-decoration: none;

  &.inactive {
    opacity: 0.5;
  }
  
  .receivers {
    display: flex;
    gap: 6px;
    overflow-x: auto;
    overflow-y: hidden;

    .receiver {
      background-color: $color-accent-lightest;
      border-radius: 6px;
      color: $color-accent;
      padding: 2px 4px;
    }
  }
}
