@use "assets/styles/global" as *;

.form {
  font-family: $main-font;
  display: flex;
  flex-direction: column;

  input {
    font-family: $main-font;
  }
}

.formsection:last-child {
  border-bottom: none;
}

.autocomplete-dropdown-icon {
  width: 1.5rem;
  height: 1.5rem;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url("../../../assets/img/icons/autocomplete-dropdown.svg");
  opacity: 0.6;
}
