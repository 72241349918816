@use "assets/styles/global" as *;

.receivers-list {
  flex-grow: 1;
  flex-direction: column;
  padding-bottom: $spacing-list-bottom-padding;
}

.card {
  &.details-card {
    .details-list .notifications-list li {
      grid-template-columns: 0.25fr 1fr;

      .checkbox-container {
        margin: 0;
      }
    }
  }
}
