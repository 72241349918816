@use "assets/styles/global" as *;

.login-page {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;

  form {
    display: flex;
    flex-direction: column;
    max-width: 350px;
    width: 50%;

    input {
      box-sizing: border-box;
      width: 100%;
    }
  }

  form.show-validation-errors {
    gap: 1rem;
  }
}
