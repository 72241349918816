/********* Colors *********/
$color-black: #292e42;
$color-white: #ffffff;
$color-mint: #f0fffb;

$color-accent: #0051ff;
$color-accent-light: #d6e3ff;
$color-accent-lightest: #ebf1ff;

$color-grey-primary: #111126;
$color-grey-secondary: #34364d;
$color-grey-tertiary: #51566b;
$color-grey-quaternary: #787b8a;

$color-grey-border: #b7bcc7;
$color-grey-background: #f5f6f7;
$color-grey-line: #dfe1e6;

$color-red: #eb150e;

$background-color: #f5f6f7;

$primary-color: #ececec;
$secondary-color: #303030;
$color-brand: #6cc4ea;

$box-shadow-color: rgba(0, 0, 0, 0.1);
$border-color: #e0e0e0;
$error-color: #b8292f;

$input-color: #f1f1f1;
$input-text-color: #303030;

$text-color: #303030;
$page-title-color: #303030;

$grid-color: #404040;
$grid-text-color: white;

$button-color: #ececec;
$button-text-color: #303030;
$accent-button-color: #ececec;
$accent-button-text-color: #303030;
$muted-button-color: #ececec;
$muted-button-text-color: #303030;

$spinner-background: rgba(48, 60, 55, 0.15);
$spinner-color: rgba(48, 60, 55, 0.8);

$datepicker-background-color: white;
$datepicker-border-color: #303030;
$datepicker-text-color: #303030;
$datepicker-disabled-text-color: #30303070;
$datepicker-selected-background-color: #303030;
$datepicker-selected-text-color: white;

$active-datepicker-background-color: #303030;

$modal-wrapper-background-color: rgba(0, 0, 0, 0.5);
$modal-background-color: #fff;

$filter-menu-background-color: #fff;

$color-lightblue: #bde9ff;
$color-blue: #0984dc;
$color-darkblue: #0d578f;
$color-lightpink: #ffc6d8;
$color-pink: #d82caa;
$color-red: #ff6057;
$color-darkred: #d62217;
$color-brown: #d98043;
$color-yellow: #fdbf2d;
$color-lightgreen: #7dd740;
$color-green: #5cb023;
$color-darkgreen: #316a0b;
$color-grey: #7e7e7e;
$color-purple: #6b296b;

/********* Fonts *********/
$main-font: "Poppins", sans-serif;

/********* Spacing *********/
$spacing-gutter: 16px;
$spacing-list-bottom-padding: 80px;