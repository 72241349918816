@use "assets/styles/global" as *;

.details-header {
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  position: relative;
  width: 100%;

  .title {
    display: flex;
    flex-shrink: 0;
    gap: 8px;

    @include sm {
      gap: 24px;
    }

    h1 {
      background-color: $color-accent-lightest;
      border-radius: 8px;
      color: $color-accent;
      font-size: 24px;
      font-weight: normal;
      padding: 8px 16px;
    }
  }

  .close-button {
    right: 0;
    top: 0;
  }
}
