@use "assets/styles/global" as *;

.notifications-list {
  flex-grow: 1;
  flex-direction: column;
  padding-bottom: $spacing-list-bottom-padding;

  .filters {
    display: flex;
    flex-direction: column;
    margin-bottom: 32px;
    gap: 16px;

    @include md {
      flex-direction: row;
    }

    .select {
      flex-grow: 1;
      width: 100%;

      @include md {
        width: 50%;
      }
    }
  }
}

.card {
  &.details-card {
    .spinner {
      background-color: rgba(white, 0.75);
      z-index: 1;
    }

    section .notification-receivers li {
      grid-template-columns: 1fr 0.35fr;
    }

    .details-list ul li {
      grid-template-columns: 1fr;

      .remove-button {
        max-width: 100px;
        justify-self: end;
      }
    }
  }

  &.create-card {
    .trigger-select .error {
      font-weight: 400;
      font-size: 12px;
      line-height: 20px;
      margin-top: 3px;
      margin-right: 14px;
      margin-bottom: 0;
      margin-left: 14px;
    }
  }
}
