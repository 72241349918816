@use "assets/styles/global" as *;

#page-header {
  flex-grow: 0;
  flex-shrink: 0;
  margin: 1rem;

  #environment-banner + .navbar {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }

  .navbar-links {
    align-items: flex-start;
    display: flex;
    gap: 8px;
  }

  .navbar-actions {
    flex-grow: 1;
    text-align: right;
  }
}
