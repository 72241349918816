@use "assets/styles/global" as *;

.create-button-link {
  bottom: $spacing-gutter;
  position: fixed;
  right: $spacing-gutter;
  text-decoration: none;

  button {
    align-items: center;
    display: flex;
    gap: 8px;
    justify-content: center;
  }
}
