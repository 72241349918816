.create-card {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  padding: 20px;

  & > .go-back-link {
    align-self: flex-start;
    margin-bottom: 40px;
  }

  .card-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 600px;
    width: 100%;

    .formsection {
      display: flex;
      flex-direction: column;
    }

    .error {
      color: $error-color;
    }

    .form-actions {
      align-items: center;
      display: flex;
      gap: 16px;
      margin-top: 32px;
    }
  }
}

.card {
  display: flex;
  flex-grow: 1;
  gap: 30px;
  padding: 30px;
  position: relative;
}

.card.details-card {
  align-items: flex-start;
  justify-content: flex-start;

  .card-content {
    display: flex;
    flex-direction: column;
    gap: 50px;
    width: 100%;
  }

  section {
    width: 100%;

    & > h1 {
      color: $color-grey-primary;
      font-size: 20px;
      margin-bottom: 16px;
    }

    p {
      color: $color-grey-secondary;
      font-size: 16px;
    }
  }

  .details-list ul {
    width: 100%;

    li {
      border-bottom: 1px solid $color-grey-line;
      display: grid;
      grid-template-columns: 1fr;
      padding: 13px 0;

      span {
        font-size: 16px;
      }
    }

    li.list-header {
      padding-top: 0;

      span {
        font-size: 12px;
        font-weight: 600;
      }
    }
  }

  .add-to-list {
    align-items: center;
    display: flex;
    gap: 8px;
    margin-top: 16px;

    .select {
      flex-grow: 1;
    }
  }
}
