.inner {
  max-width: 1180px;
  margin: 0 auto;
  padding: 0 1rem;

  color: $text-color;
  background-color: $background-color;
}

.noscroll {
  // Preventing scroll on main content when overlay on mobile
  overflow: hidden;

  @include md {
    overflow: auto;
  }
}

.page-view {
  display: flex;
  flex-direction: column;
  height: 100%;
  min-height: 100vh;
}

