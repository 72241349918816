@use "assets/styles/global" as *;

#environment-banner {
  align-items: center;
  background-color: $color-accent;
  box-sizing: border-box;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  color: $color-white;
  display: flex;
  justify-content: center;
  padding: 4px;

  .name {
    font-size: 16px;
    text-transform: uppercase;
  }
}
