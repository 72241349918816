@use "assets/styles/global" as *;

input {
  position: relative;
  display: block;

  background-color: $input-color;
  color: $input-text-color;

  &:focus {
    outline: none;
  }

  &::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    font-weight: 900;
    font-size: 16px;
    color: $input-text-color;
  }

  &::-moz-placeholder {
    /* Firefox 19+ */
    font-weight: 900;
    font-size: 16px;
    color: $input-text-color;
  }

  &:-ms-input-placeholder {
    /* IE 10+ */
    font-weight: 900;
    font-size: 16px;
    color: $input-text-color;
  }

  &:-moz-placeholder {
    /* Firefox 18- */
    font-weight: 900;
    font-size: 16px;
    color: $input-text-color;

    opacity: 1;
  }
}

// With a label
.input-item {
  margin-bottom: 1rem;
  margin-top: 0.1rem;

  @include md {
    margin-top: 1rem;
  }

  input {
    width: calc(100% - 40px);
    border: 0;
    padding: 0 1rem;
    border-radius: 8px;
    font-weight: 500;
    font-size: 18px;
    height: 50px;
  }

  .floating-label {
    position: relative;
    overflow: hidden;

    label {
      position: absolute;
      top: 3px;
      left: 18px;
      z-index: 2;
      font-size: 12px;
      pointer-events: none;
      -moz-transform: translateY(-20px);
      -ms-transform: translateY(-20px);
      -webkit-transform: translateY(-20px);
      transform: translateY(-20px);
      -moz-transition: -moz-transform 0.15s ease-out;
      -o-transition: -o-transform 0.15s ease-out;
      -webkit-transition: -webkit-transform 0.15s ease-out;
      transition: transform 0.15s ease-out;

      color: $input-text-color;
      font-family: $main-font;
    }

    label.active {
      -moz-transform: translateY(0px);
      -ms-transform: translateY(0px);
      -webkit-transform: translateY(0px);
      transform: translateY(0px);
    }
  }
}

.input-error {
  cursor: default;
  font-weight: 700;
  font-size: 0.9em;
  padding-left: 1.1em;
  margin-bottom: -1.125em;

  color: $error-color;
  font-family: $main-font;
  border-top: 2px solid $error-color;
}
